import React, { useEffect, useReducer, useRef, useState } from 'react';
import moment from 'moment';
import Proptypes from 'prop-types';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { migrateAccountToV4 } from '@frameio/core/src/accounts/services';
import config from 'config';
import track from 'analytics';
import { Tooltip } from '@frameio/vapor';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import { MEDIUM_MAX } from 'utils/breakpoints';
import PauseIcon from '@frameio/components/src/svgs/raw/ic-player-pause-16px.svg';
import PlayIcon from '@frameio/components/src/svgs/raw/ic-player-play-16px.svg';
import Spinner from '@frameio/components/src/components/Spinner';
import {
  SPINNER_COLORS,
  SPINNER_SIZES,
} from '@frameio/components/src/utils/spinnerUtils';
import migrationModalBg from 'images/migrationmodalbg.jpg';
import regularWoff2 from './fonts/Inter-Regular.woff2';
import mediumWoff2 from './fonts/Inter-Medium.woff2';
import frameGothicMediumWoff2 from './fonts/FrameGothic-Medium.woff2';
import IconBox from './icons/icon-box.svg';
import IconCode from './icons/icon-code.svg';
import IconComments from './icons/icon-comments.svg';
import IconInfo from './icons/icon-info.svg';
import IconLock from './icons/icon-lock.svg';
import IconNle from './icons/icon-nle.svg';
import IconSlack from './icons/icon-slack.svg';
import { MODAL_VARIANT_BETA_V4 } from './variant';

export const LOCAL_STORAGE_MIGRATION_MODAL_KEY = 'hasSeenMigrationModal';
const TOAST_TIMEOUT = 5000;
const URL_V4_MARKETING = 'https://frame.io/v4';
const URL_V4_ENTERPRISE_SUPPORT =
  'https://help.frame.io/en/articles/9084098-frame-io-v4-enterprise-support';
const URL_V4_TERMS =
  'https://frame.io/terms#:~:text=Prelease%20or%20Beta%20Version';
const URL_V4_FEATURES =
  'https://help.frame.io/en/articles/9084073-frame-io-v4-v3-feature-comparison';
const URL_V4_PRICING = 'https://frame.io/pricing';

const TEXT = {
  contactAdmin: 'Reach out to your admin to upgrade to V4',
  copyMobile:
    'Built on a powerful new metadata framework, V4 Beta gives you access to features that improve flexibility, collaboration, and efficiency more than ever before.',
  ctaContinue: 'Continue',
  ctaLaterEligible: 'Later',
  ctaLaterIneligible: 'No, Thanks',
  ctaLearnMore: 'Learn More About the V4 Beta',
  ctaLearnMoreSimple: 'Learn More',
  ctaMobile: 'Upgrade available on desktop',
  ctaWaitlist: 'Join the waitlist',
  ctaWaitlistSuccess: `You’ve been added to the waitlist`,
  dialogTitleEligible: 'Your account is eligible!',
  dialogTitleIneligible: (isEnterprise) =>
    isEnterprise ? 'Coming soon' : 'Coming soon for your account',

  disclaimerEnterprise:
    'Currently available on our Free, Pro, and Team plans only',
  subtitle: (isEnterprise) =>
    isEnterprise
      ? 'Once available on Enterprise plans, V4 will give you access to a powerful metadata framework, improving flexibility, collaboration, and efficiency.'
      : 'Built on a powerful new metadata framework, V4 Beta gives you access to features that improve flexibility, collaboration, and efficiency more than ever before.',
  titleEligible: () => (
    <>
      Unlock the new <br />
      Frame.io V4 Beta
    </>
  ),
  titleEligibleMobile: 'Unlock the new Frame.io V4 Beta',
  titleIneligible: (isEnterprise) =>
    isEnterprise ? (
      <>
        New Frame.io <br /> Version 4 Beta
      </>
    ) : (
      <>
        Discover the new <br /> Frame.io V4 Beta
      </>
    ),
  titleIneligibleMobile: 'Discover the new Frame.io V4 Beta',
  titleMigrating: () => (
    <>
      Upgrading Your
      <br /> Account to V4 Beta
    </>
  ),
  tooltipLater: 'You can upgrade anytime from your account menu.',
  errorTitle: 'There was an issue upgrading your account.',
  errorDescription:
    'If the issue persist, please reach out to support@frame.io',
  errorCta: 'Retry',
  migrationDetails: {
    ctaLater: 'Later',
    ctaMigrate: 'Upgrade Now',
    ctaTerms: 'Read the V4 Beta Terms',
    featuresTitle: 'Not Yet Available in V4 Beta:',
    featuresList: {
      nle: {
        icon: () => <IconNle />,
        title: 'NLE Integrations',
      },
      privacy: {
        icon: () => <IconLock />,
        title: 'Private projects and folders',
      },
      comments: { icon: () => <IconComments />, title: 'Team-only comments' },
      apis: { icon: () => <IconCode />, title: 'Webhooks / APIs / Zapier' },
      integrations: { icon: () => <IconSlack />, title: 'Slack integrations' },
      archiving: { icon: () => <IconBox />, title: 'Archiving projects' },
    },
    overview: (handleLearnMore) => (
      <>
        <StyledTextBlock>
          Your account’s users and content will be upgraded to the V4 Beta
          experience. While some legacy features aren’t supported yet, all
          existing content (including Review Links and Presentations) will still
          be usable and available to anyone with access.{' '}
          <a
            href={URL_V4_FEATURES}
            onClick={handleLearnMore}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </StyledTextBlock>

        <StyledTextBlock>
          Your current subscription pricing will stay the same, but there may be
          some slight differences in your plan’s features, users and storage
          limits.{' '}
          <a
            href={URL_V4_PRICING}
            onClick={handleLearnMore}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </StyledTextBlock>

        <StyledSmallTextBlock>
          Ensure all ongoing tasks are completed before upgrading. You can
          revert to the legacy experience by contacting customer support.
        </StyledSmallTextBlock>
      </>
    ),
    title: 'What to expect',
  },
};

const CloseIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="x">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7804 8.28033C21.0733 7.98744 21.0733 7.51256 20.7804 7.21967C20.4875 6.92678 20.0126 6.92678 19.7197 7.21967L14 12.9393L8.28033 7.21967C7.98744 6.92678 7.51256 6.92678 7.21967 7.21967C6.92678 7.51256 6.92678 7.98744 7.21967 8.28033L12.9393 14L7.21967 19.7197C6.92678 20.0126 6.92678 20.4874 7.21967 20.7803C7.51256 21.0732 7.98744 21.0732 8.28033 20.7803L14 15.0607L19.7197 20.7803C20.0126 21.0732 20.4874 21.0732 20.7803 20.7803C21.0732 20.4874 21.0732 20.0126 20.7803 19.7197L15.0607 14L20.7804 8.28033Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </g>
  </svg>
);

const CloseIconMobile = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70711 3.79289C4.31658 3.40237 3.68342 3.40237 3.29289 3.79289C2.90237 4.18342 2.90237 4.81658 3.29289 5.20711L6.58579 8.5L3.29289 11.7929C2.90237 12.1834 2.90237 12.8166 3.29289 13.2071C3.68342 13.5976 4.31658 13.5976 4.70711 13.2071L8 9.91421L11.2929 13.2071C11.6834 13.5976 12.3166 13.5976 12.7071 13.2071C13.0976 12.8166 13.0976 12.1834 12.7071 11.7929L9.41421 8.5L12.7071 5.20711C13.0976 4.81658 13.0976 4.18342 12.7071 3.79289C12.3166 3.40237 11.6834 3.40237 11.2929 3.79289L8 7.08579L4.70711 3.79289Z"
      fill="#676A71"
    />
  </svg>
);

/**
 * The migration dialog uses a variety of design details and styles that do not match the existing design system of V3.
 * These designs are very specific and should never change without explicit direction, so we are intentionally not using
 * Vapor or styles from web-components in order to ensure the design does not unexpectedly shift due to side effects
 * from changes elsewhere.
 */

export const fontFaces = css`
  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url(${regularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${mediumWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Frame Gothic';
    font-style: normal;
    font-weight: 600;
    src: url(${frameGothicMediumWoff2}) format('woff2');
  }
`;

const StyledContainer = styled.div`
  ${fontFaces}
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background: #151723;
  border-radius: 24px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.36),
    0px 32px 28px 0px rgba(0, 0, 0, 0.32), 0px 1px 32px 0px rgba(0, 0, 0, 0.28);
  box-sizing: border-box;
  max-width: 900px;
  overflow: hidden;
  padding: 64px;
  position: relative;

  &:before {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 1065px;
    content: '';
    display: block;
    filter: blur(100px);
    height: 1065px;
    left: -382px;
    mix-blend-mode: overlay;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    top: -357.5px;
    width: 1065px;
    z-index: 1;
  }

  @media (max-width: ${MEDIUM_MAX}px) {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 16px;
    max-width: 468px;
    padding: 24px;

    &:before {
      display: none;
    }
  }

  @media (max-height: 800px) {
    transform: scale(0.85);
  }

  @media (max-height: 700px) {
    transform: scale(0.75);
  }
`;

const StyledMobileModalContent = styled.div`
  display: none;
  font-family: 'Avenir Next';

  @media (max-width: ${MEDIUM_MAX}px) {
    display: block;
  }
`;

const StyledMobileDialogTitle = styled.h3`
  color: #0f1013;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 16px;
`;

const StyledMobileTitle = styled.h2`
  color: #20222a;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 115.789% */
  padding-bottom: 16px;
`;

const StyledMobileCopy = styled.p`
  color: #7e829a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 16px;
`;

const StyledMobileCta = styled.div`
  border: 1px solid #d5d8e0;
  border-radius: 6px;
  color: #676a71;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: normal;
  padding: 10px 0px;
`;

const StyledPrimaryModalContent = styled.div`
  @media (max-width: ${MEDIUM_MAX}px) {
    display: none;
  }
`;

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  color: rgba(189, 192, 212, 0.7);
  cursor: pointer;
  outline: none;
  height: 28px;
  width: 28px;

  &:hover {
    color: rgba(230, 232, 241, 0.8);
  }

  &:focus,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.4);
    color: rgba(230, 232, 241, 0.8);
  }

  @media (max-width: ${MEDIUM_MAX}px) {
    position: absolute;
    right: 34px;
    top: 20px;
  }
`;

const StyledDialogTitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 6px;
  cursor: default;
  margin-right: 12px;
`;

const StyledDialogTitle = styled.h3`
  color: #6972fb;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
`;

const StyledTitle = styled.h2`
  color: #fff;
  font-family: 'Frame Gothic', sans-serif;
  flex-shrink: 0;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.255px;
  line-height: 48px; /* 141.176% */
  padding-right: 89px;
`;

const StyledSubtitle = styled.p`
  color: rgba(212, 214, 230, 0.75);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
`;

const StyledFlexRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledVideoContainer = styled.div`
  background-image: url(${migrationModalBg});
  box-shadow: 6px 18px 41px 0px rgba(0, 0, 0, 0.1),
    24px 72px 75px 0px rgba(0, 0, 0, 0.09),
    53px 161px 102px 0px rgba(0, 0, 0, 0.05),
    95px 287px 121px 0px rgba(0, 0, 0, 0.01),
    148px 448px 132px 0px rgba(0, 0, 0, 0);
  height: 375px;
  margin: 36px -64px 48px;
  position: relative;
  width: 900px;

  video {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const StyledScrim = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
`;

const StyledPausePlayButton = styled.button`
  background: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 2;

  &:hover,
  &:focus-visible {
    opacity: 1 !important;
  }
`;

const StyledCta = styled.a`
  color: #6972fb;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  text-decoration-line: underline;

  &:focus-visible,
  &:hover {
    text-decoration: none;
  }
`;

const StyledEnterpriseDisclaimer = styled.span`
  color: rgba(189, 192, 212, 0.7);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

const primaryButtonStyles = css`
  background: ${(p) =>
    p.variant === 'secondary' ? 'rgba(85, 88, 110, 0.40)' : '#5B53FF'};
  border: none;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 20px; /* 142.857% */
  margin-left: 16px;
  padding: 4px 20px;
  text-align: center;

  &:hover,
  &:focus-visible {
    background: ${(p) =>
      p.variant === 'secondary' ? 'rgba(109,113,136,.5)' : '#6972fb'};
  }

  &:focus,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.4);
    outline: none;
  }
`;

const StyledButtonCta = styled.a`
  ${primaryButtonStyles}
  align-items: center;
  display: flex;
`;

const StyledButtonGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  ${primaryButtonStyles}
`;

const StyledTooltipForLater = styled(Tooltip)`
  max-width: 360px;
  top: -4px;
`;

const StyledTextForAdminRequest = styled.span`
  align-items: center;
  border-radius: 20px;
  background: rgba(44, 48, 65, 0.3);
  color: rgba(189, 192, 212, 0.7);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 20px; /* 142.857% */
  padding: 0 20px;
  text-align: center;
`;

const StyledTextBlock = styled.p`
  color: rgba(212, 214, 230, 0.75);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  padding-bottom: 27px;

  a {
    color: rgba(212, 214, 230, 0.75);
    text-decoration: underline;
  }
`;

const StyledSmallTextBlock = styled.p`
  color: rgba(189, 192, 212, 0.7);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 0;
`;

const StyledFeatureList = styled.div`
  background: rgba(240, 205, 51, 0.1);
  border: 1px solid rgba(240, 205, 51, 0.1);
  border-radius: 24px;
  box-shadow: 11px 14px 40px 0px rgba(0, 0, 0, 0.2),
    45px 57px 73px 0px rgba(0, 0, 0, 0.17),
    102px 128px 98px 0px rgba(0, 0, 0, 0.1),
    182px 228px 117px 0px rgba(0, 0, 0, 0.03),
    284px 356px 127px 0px rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin-left: 56px;
  min-width: 321px;
  padding: 32px;
  position: relative;
  z-index: 1;

  ul {
    list-style: none;
    padding: 0;
    position: relative;
    z-index: 3;
  }

  li {
    align-items: center;
    color: rgba(243, 243, 247, 0.9);
    display: flex;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    padding-bottom: 12px;

    &:last-child {
      padding-bottom: 0;
    }

    svg {
      color: rgba(230, 232, 241, 0.8);
      margin-right: 8px;
    }
  }
`;

const StyledFeatureListTitle = styled.div`
  align-items: center;
  color: #f0cd33;
  display: flex;
  padding-bottom: 24px;
  position: relative;
  z-index: 3;

  h4 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    padding-left: 8px;
  }
`;

const StyledErrorButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin-left: 12px;
  padding: 0;
`;

const V4BetaMigrationModal = ({
  accountColor,
  accountId,
  accountImage,
  accountName,
  canUserInitiateAccountMigration,
  closeModal,
  isAccountEligibleForMigration,
  isEnterprise,
  showErrorToast,
  showSuccessToast,
  userEmail,
}) => {
  const primaryCtaRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    /**
     * The linter complains about the optional chaining here for some reason, but it's required to prevent a runtime
     * error
     */
    // eslint-disable-next-line no-unused-expressions
    primaryCtaRef.current?.focus();
  }, []);

  const canMigrate =
    canUserInitiateAccountMigration && isAccountEligibleForMigration;
  const [userWantsToContinue, setUserWantsToContinue] = useState(false);
  const [videoIsPaused, setVideoIsPaused] = useState(false);
  const [videoHasLoaded, setVideoHasLoaded] = useReducer(() => true, false);
  const [isMigrating, setIsMigrating] = useState(false);

  const playPauseVideo = () => {
    const video = videoRef.current;
    if (video?.paused) {
      video.play();
      setVideoIsPaused(false);
    } else {
      video.pause();
      setVideoIsPaused(true);
    }
  };

  const handleContinue = () => {
    track('v4-upgrade-disclaimers-modal-shown', {
      variant: MODAL_VARIANT_BETA_V4,
    });
    setUserWantsToContinue(true);
  };

  const joinWaitlist = () => {
    track('v4-upgrade-waitlist-joined', {
      email: userEmail,
      variant: MODAL_VARIANT_BETA_V4,
    });
    closeModal();

    showSuccessToast({
      autoCloseDelay: TOAST_TIMEOUT,
      header: TEXT.ctaWaitlistSuccess,
    });
  };

  const handleLearnMore = () => {
    track('v4-upgrade-learn-more-clicked', { variant: MODAL_VARIANT_BETA_V4 });
  };

  const handleClose = (buttonClicked) => {
    localStorage.setItem(
      `${LOCAL_STORAGE_MIGRATION_MODAL_KEY}-${accountId}`,
      moment()
    );

    const modalName = userWantsToContinue
      ? 'Migration Disclaimers'
      : 'Migration Awareness';

    track('v4-upgrade-modal-dismissed', {
      button_clicked: buttonClicked,
      modal_name: modalName,
      variant: MODAL_VARIANT_BETA_V4,
    });

    closeModal();
  };

  const handleMigrate = () => {
    setIsMigrating(true);
    track('v4-upgrade-initiated', { variant: MODAL_VARIANT_BETA_V4 });

    migrateAccountToV4({ accountId })
      .then(() => {
        /**
         * Once a migration has successfully been initiated, reloading the app will result in being redirected to V4.
         */
        window.location.assign(`${config.nextUrl}?a=${accountId}`);
      })
      .catch((error) => {
        setIsMigrating(false);
        track('v4-upgrade-error-shown', {
          type_of_error: error?.response?.data?.status?.reason,
          variant: MODAL_VARIANT_BETA_V4,
        });

        closeModal();

        showErrorToast({
          autoCloseDelay: TOAST_TIMEOUT,
          header: (
            <>
              {TEXT.errorTitle}{' '}
              <span style={{ color: 'rgba(189, 192, 212, 0.7)' }}>
                {TEXT.errorDescription}
              </span>
              <StyledErrorButton disabled={isMigrating} onClick={handleMigrate}>
                {TEXT.errorCta}
              </StyledErrorButton>
            </>
          ),
          style: { maxWidth: 'none' },
        });
      });
  };

  return (
    <StyledContainer>
      <StyledMobileModalContent>
        <StyledCloseButton onClick={() => handleClose('X')}>
          <CloseIconMobile />
        </StyledCloseButton>
        <StyledMobileDialogTitle>
          {isAccountEligibleForMigration
            ? TEXT.dialogTitleEligible
            : TEXT.dialogTitleIneligible(isEnterprise)}
        </StyledMobileDialogTitle>

        <StyledMobileTitle>
          {isAccountEligibleForMigration
            ? TEXT.titleEligibleMobile
            : TEXT.titleIneligibleMobile}
        </StyledMobileTitle>

        <StyledMobileCopy>{TEXT.copyMobile}</StyledMobileCopy>

        <StyledMobileCta>{TEXT.ctaMobile}</StyledMobileCta>
      </StyledMobileModalContent>

      <StyledPrimaryModalContent>
        <StyledFlexRow style={{ paddingBottom: 20 }}>
          <StyledDialogTitleContainer>
            {!userWantsToContinue && (
              <StyledAvatar
                color={accountColor}
                image={accountImage}
                name={accountName ? accountName[0] : ''}
                size={32}
              />
            )}

            <StyledDialogTitle>
              {userWantsToContinue ? (
                <>{TEXT.migrationDetails.title}</>
              ) : (
                <>
                  {isAccountEligibleForMigration
                    ? TEXT.dialogTitleEligible
                    : TEXT.dialogTitleIneligible(isEnterprise)}
                </>
              )}
            </StyledDialogTitle>
          </StyledDialogTitleContainer>
          <StyledCloseButton onClick={() => handleClose('X')}>
            <CloseIcon />
          </StyledCloseButton>
        </StyledFlexRow>
        <AnimatePresence key={`continue-${userWantsToContinue}`}>
          {/**
           * The second modal screen
           * Mount if the user has clicked the Continue button
           */}
          {userWantsToContinue ? (
            <motion.div
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <StyledFlexRow style={{ paddingBottom: 45 }}>
                <StyledTitle>{TEXT.titleMigrating()}</StyledTitle>
              </StyledFlexRow>

              <StyledFlexRow style={{ alignItems: 'flex-start' }}>
                <div>{TEXT.migrationDetails.overview(handleLearnMore)}</div>
                <StyledFeatureList>
                  <StyledFeatureListTitle>
                    <IconInfo /> <h4>{TEXT.migrationDetails.featuresTitle}</h4>
                  </StyledFeatureListTitle>
                  <ul>
                    {Object.keys(TEXT.migrationDetails.featuresList).map(
                      (feature, idx) => (
                        <li key={`${feature}-${idx}`}>
                          {TEXT.migrationDetails.featuresList[feature].icon()}{' '}
                          {TEXT.migrationDetails.featuresList[feature].title}
                        </li>
                      )
                    )}
                  </ul>
                </StyledFeatureList>
              </StyledFlexRow>

              <StyledFlexRow style={{ paddingTop: 40 }}>
                <StyledCta
                  href={URL_V4_TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {TEXT.migrationDetails.ctaTerms}
                </StyledCta>
                <StyledButtonGroup>
                  <StyledTooltipForLater
                    disabled={!isAccountEligibleForMigration}
                    title={TEXT.tooltipLater}
                    placement="top"
                    shouldUsePortal
                  >
                    <StyledButton
                      onClick={() => handleClose('Later')}
                      variant="secondary"
                    >
                      {TEXT.migrationDetails.ctaLater}
                    </StyledButton>
                  </StyledTooltipForLater>

                  <StyledButton
                    disabled={isMigrating}
                    onClick={handleMigrate}
                    variant="primary"
                  >
                    {TEXT.migrationDetails.ctaMigrate}
                  </StyledButton>
                </StyledButtonGroup>
              </StyledFlexRow>
            </motion.div>
          ) : (
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              <StyledFlexRow>
                <StyledTitle>
                  {isAccountEligibleForMigration
                    ? TEXT.titleEligible()
                    : TEXT.titleIneligible(isEnterprise)}
                </StyledTitle>

                <StyledSubtitle>{TEXT.subtitle(isEnterprise)}</StyledSubtitle>
              </StyledFlexRow>

              <StyledVideoContainer>
                <video
                  autoPlay
                  id="inline-upgrade-video"
                  loop
                  muted
                  name="media"
                  onCanPlayThrough={() => {
                    setVideoHasLoaded();
                    videoRef.current.play();
                  }}
                  playsInline
                  preload="metadata"
                  ref={videoRef}
                >
                  <source
                    src="https://embed.wistia.com/deliveries/3b1ba568d205efc24e8f5d4e4e03a561.mp4"
                    type="video/mp4"
                  />
                </video>

                <StyledScrim
                  style={{
                    opacity: !videoHasLoaded ? 1 : 0,
                  }}
                >
                  <Spinner
                    color={SPINNER_COLORS.white}
                    size={SPINNER_SIZES.S}
                    spinning
                  />
                </StyledScrim>

                {videoHasLoaded && (
                  <StyledPausePlayButton
                    aria-controls="inline-upgrade-video"
                    aria-label={videoIsPaused ? 'Play video' : 'Pause video'}
                    onClick={playPauseVideo}
                    show={videoIsPaused}
                    style={{ opacity: videoIsPaused ? 1 : 0 }}
                  >
                    {videoIsPaused ? <PlayIcon /> : <PauseIcon />}
                  </StyledPausePlayButton>
                )}
              </StyledVideoContainer>

              <StyledFlexRow>
                {isEnterprise ? (
                  <StyledEnterpriseDisclaimer>
                    {TEXT.disclaimerEnterprise}
                  </StyledEnterpriseDisclaimer>
                ) : (
                  <StyledCta
                    href={URL_V4_MARKETING}
                    onClick={handleLearnMore}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {TEXT.ctaLearnMore}
                  </StyledCta>
                )}

                {/**
                 * If an account is eligible for migration but the current user is not an admin of the account, provide a
                 * cta to contact their admin. Otherwise, render the main cta buttons.
                 */}
                {!canUserInitiateAccountMigration &&
                isAccountEligibleForMigration ? (
                  <StyledTextForAdminRequest>
                    {TEXT.contactAdmin}
                  </StyledTextForAdminRequest>
                ) : (
                  <StyledButtonGroup>
                    {isEnterprise ? (
                      <StyledButtonCta
                        ref={primaryCtaRef}
                        href={URL_V4_ENTERPRISE_SUPPORT}
                        onClick={handleLearnMore}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {TEXT.ctaLearnMoreSimple}
                      </StyledButtonCta>
                    ) : (
                      <>
                        <StyledTooltipForLater
                          disabled={!isAccountEligibleForMigration}
                          title={TEXT.tooltipLater}
                          placement="top"
                          shouldUsePortal
                        >
                          <StyledButton
                            onClick={() => handleClose('Later')}
                            variant="secondary"
                          >
                            {/**
                             * If eligible for migration, render the standard button. If a user was presented with the modal
                             * even though the account is ineligible for migration render an alternate "later" button.
                             */}
                            {canMigrate
                              ? TEXT.ctaLaterEligible
                              : TEXT.ctaLaterIneligible}
                          </StyledButton>
                        </StyledTooltipForLater>

                        <StyledButton
                          ref={primaryCtaRef}
                          onClick={canMigrate ? handleContinue : joinWaitlist}
                          variant="primary"
                        >
                          {/**
                           * If eligible for migration, render the Continue button. Otherwise if a user was presented with the
                           * modal even though the account is ineligible for migration, render the cta to join the waitlist.
                           */}
                          {canMigrate ? TEXT.ctaContinue : TEXT.ctaWaitlist}
                        </StyledButton>
                      </>
                    )}
                  </StyledButtonGroup>
                )}
              </StyledFlexRow>
            </motion.div>
          )}
        </AnimatePresence>
      </StyledPrimaryModalContent>
    </StyledContainer>
  );
};

export default V4BetaMigrationModal;

V4BetaMigrationModal.propTypes = {
  accountColor: Proptypes.string,
  accountId: Proptypes.string,
  accountImage: Proptypes.string,
  accountName: Proptypes.string,
  canUserInitiateAccountMigration: Proptypes.bool,
  closeModal: Proptypes.func.isRequired,
  isAccountEligibleForMigration: Proptypes.bool,
  isEnterprise: Proptypes.bool,
  showErrorToast: Proptypes.func.isRequired,
  showSuccessToast: Proptypes.func.isRequired,
  userEmail: Proptypes.string,
};
