import React from 'react';
import V4MigrationModal from './V4MigrationModal';
import V4BetaMigrationModal from './V4BetaMigrationModal';

const MigrationModal = ({ isV4MigrationModalEnabled, ...props }) => {
  if (isV4MigrationModalEnabled) {
    return <V4MigrationModal {...props} />;
  }

  return <V4BetaMigrationModal {...props} />;
};

export default MigrationModal;
