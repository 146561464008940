import SharesIcon from './icons/icon-shares.svg';
import MetadataIcon from './icons/icon-metadata.svg';
import CollectionsIcon from './icons/icon-collections.svg';
import AttachmentsIcon from './icons/icon-attachments.svg';
import ViewerIcon from './icons/icon-viewer.svg';
import FoldersIcon from './icons/icon-folders.svg';
import BrandingIcon from './icons/icon-branding.svg';
import IntegrationsIcon from './icons/icon-integrations.svg';
import SortIcon from './icons/icon-sort.svg';
import AppIntegrationIcon from './icons/icon-app-integration.svg';
import NotSupportedIcon from './icons/icon-not-supported.svg';

export const features = {
  newInV4: {
    title: 'What’s New',
    content: [
      {
        title: 'Powerful Shares',
        description:
          'Create captivating presentations with custom colors, your logo, layout, and view configurations.',
        icon: SharesIcon,
      },
      {
        title: 'Custom Metadata',
        description:
          'Tag, track, and organize your media with custom or 32 out-of-the-box metadata fields.',
        icon: MetadataIcon,
      },
      {
        title: 'Collections',
        description:
          'Group and sort media assets into Collections to craft the perfect workflow for your team.',
        icon: CollectionsIcon,
      },
      {
        title: 'Comment Attachments',
        description:
          'Attach references, links, and other assets with your feedback for added context.',
        icon: AttachmentsIcon,
      },
      {
        title: 'Enhanced Viewer',
        description:
          'Transform your review process with precise frame previews, improved controls, and high-quality playback.',
        icon: ViewerIcon,
      },
    ],
  },

  comingSoon: {
    title: 'Not Available, Coming Soon',
    content: [
      {
        title: 'Private Folders & Assets',
        description:
          'To keep existing private folders & assets private, move them to a new project before upgrading.',
        icon: FoldersIcon,
      },
      {
        title: 'Default Share & Email Branding',
        description:
          'Share customization has been redesigned, with default branding in development.',
        icon: BrandingIcon,
      },
      {
        title: 'API Integrations, Custom Actions & Webhooks',
        description:
          'Custom integrations (like Zapier) and webhooks won’t work until the new Frame API is available.',
        icon: IntegrationsIcon,
      },
      {
        title: 'Custom Sort in Shares',
        description:
          'Assets can still be sorted by field values until custom reordering is available.',
        icon: SortIcon,
      },
      {
        title: 'Premiere Pro & After Effects Integrations',
        description: 'Native integrations are in development.',
        icon: AppIntegrationIcon,
      },
    ],
  },

  notSupported: {
    title: 'Not Supported',
    content: [
      {
        title: 'DaVinci Resolve Integration',
        description: 'Alternatively, you can export comments from Frame.io.',
        icon: NotSupportedIcon,
      },
    ],
  },
};

export default features;
