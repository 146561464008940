import { partialRight } from 'lodash';
import { createSelector } from 'reselect';
import {
  featureFlagByUserEntitiesSelector,
  featureFlagByAccountEntitiesSelector,
} from '@frameio/core/src/featureFlags/selectors';
import store from 'configureStore';
import { currentUserSelector } from 'selectors/users';
import { currentAccountSelector } from 'selectors/accounts';

export const Flags = {
  // 👋 Let's keep this list sorted alphabetically! 👋
  ACCOUNT_SETTINGS_PERMISSION_CHECKS: 'web.account_settings.permission_checks',
  ADMIN_CONTROLS_SETTINGS: 'web.account_settings.admin_control_settings',
  AEM_INTEGRATION: 'web.assets.aem_integration',
  ALLOWED_DOMAINS: 'web.account_settings.allowed_domains',
  API_V2_AUTH_COOKIE_CALLBACK: 'api.v2.auth_cookie_callback',
  APPS: 'web.account_settings.apps',
  ARCHIVAL_STORAGE: 'web.dashboard.archival_storage',
  BETTER_USER_MANAGEMENT: 'web.account_settings.better_user_management',
  BROADCAST_PLAYHEAD: 'web.player.broadcast_playhead',
  C2C_REALTIME_LOGGING: 'web.c2c.realtime_logging',
  COMMENT_TIMESTAMP_EDITING: 'web.player.comment_timestamp_editing',
  DEVELOPER_TOKENS: 'web.account_settings.personal_access_tokens',
  DISABLE_ASSET_PUBLISHING: 'web.account_settings.disable_asset_publishing',
  DISABLE_PUBLIC_SHARING: 'web.account_settings.disable_public_sharing',
  DRM_SETTINGS_UI: 'web.account_settings.digital_rights_management',
  DUAL_VIEW: 'web.dual_view',
  ENTERPRISE_DATA_RESTRICTIONS: 'web.account_settings.restrict_data',
  FOLDER_SHARING: 'web.folder_sharing',
  FORCED_VERSIONING: 'web.forced_versioning',
  GROUPS_MANAGEMENT: 'web.account_settings.groups_management',
  NEW_COLLABORATOR: 'web.roles.new_collaborator',
  NEW_PROJECT_INVITE_LINKS: 'api.projects.new_invite_links',
  NEW_SHARING_MODAL: 'web.account_settings.new_sharing_modal',
  PIPELINE_DOCUMENT_SERVICE: 'pipeline.use_document_service',
  PLAYER_360: 'web.player.360_video',
  PRIVATE_PROJECT_PERMISSIONS:
    'web.account_settings.private_project_permissions',
  PSEUDO_MOBILE_FULLSCREEN: 'web.player.pseudo_mobile_fullscreen',
  REMOVE_PRINT_COMMENTS_OPTION: 'web.remove_print_comments_option',
  RESTRICTED_DOWNLOAD_OPTIONS:
    'web.account_settings.restricted_download_options',
  SECURE_SHARING: 'web.account_settings.secure_sharing',
  STICKY_LINK_SETTINGS: 'review_link.sticky_link_settings',
  TEAM_WATERMARKING_COLUMN: 'web.account_settings.team_watermarking_column',
  TOGGLE_REVIEW_LINK_COMMENTS: 'review_link.allow_toggling_comments',
  TRACKING_DEBUG: 'web.tracking.debug',
  UPDATED_ARCHIVAL_MESSAGING: 'web.project.updated_archival_messaging',
  V3_API: 'api.v3',
  V4_IS_BETA: 'v4.is_beta',
  V4_MIGRATION_MODAL: 'v3.v4_migration_modal',
  V8_PRICING_ONLY_AND_MIGRATION_FLOWS_UPDATES:
    'v3.v8_pricing_only_and_migration_flows_updates',
  WATERMARK_DOWNLOAD: 'api.watermark_download',
  WATERMARK_ID_END_USER_OVERRIDE: 'watermark_id.end_user_override',
  WEBHOOKS: 'web.account_settings.webhooks',
  // 👋 Let's keep this list sorted alphabetically! 👋
};

/**
 * @returns {Object} - Feature flags by current user.
 */
const currentUserFeatureFlagsSelector = createSelector(
  [featureFlagByUserEntitiesSelector, currentUserSelector],
  (featureFlagsByUser, currentUser) => featureFlagsByUser[currentUser.id]
);

/**
 * @returns {Object} - Feature flags by current account.
 */
export const currentAccountFeatureFlagsSelector = createSelector(
  [featureFlagByAccountEntitiesSelector, currentAccountSelector],
  (featureFlagsByAccount, currentAccount) =>
    featureFlagsByAccount[currentAccount.id]
);

export function hasFetchedFlagsSelector(state) {
  return !!(
    currentUserFeatureFlagsSelector(state) ||
    currentAccountFeatureFlagsSelector(state)
  );
}

function getFlagValue(state, flagName) {
  // Let local flags take precedence
  let storedValue;

  try {
    storedValue = window.localStorage.getItem(`ff-${flagName}`);
  } catch (e) {
    // if localStorage errors, e.g., in case storage is full or
    // disabled by user preference, don't worry about it.
  }

  // Note that localStorage always store strings, hence 'true' in quotes.
  if (storedValue !== null) {
    return storedValue === 'true';
  }
  const featureFlagsForUser = currentUserFeatureFlagsSelector(state) || {};
  const featureFlagsForAccount =
    currentAccountFeatureFlagsSelector(state) || {};

  return (
    featureFlagsForUser[flagName] || featureFlagsForAccount[flagName] || false
  );
}

const createFeatureFlagSelector = (flagName) =>
  partialRight(getFlagValue, flagName);

/**
 * This allows for backwards compatibility with the legacy feature flag pattern
 * that selects state from `store.getState()` directly. This is useful in cases
 * where a feature flag is required to be called in the legacy app (e.g.,
 * FIOAccountSettingsPage), but may result in rendering stale state if user or
 * account information for feature flags has been updated.
 *
 * For more information, see: https://github.com/Frameio/web-client/pull/8320
 *
 * @deprecated
 */
const createLegacyGetFlagValue = (flagName) => {
  const featureFlagSelector = createFeatureFlagSelector(flagName);

  return () => {
    const potentiallyStaleState = store.getState();
    return featureFlagSelector(potentiallyStaleState);
  };
};

/**
 * @returns {Array} - An array of enabled feature flags.
 */
export const enabledFeatureFlagsSelector = (state) => {
  return hasFetchedFlagsSelector(state)
    ? Object.values(Flags).filter((flag) => getFlagValue(state, flag))
    : null;
};

/**
 * Sets a localstorage value for a flag to toggle in dev/local/staging.
 */
export function setLocalFlag(flagName, value) {
  window.localStorage.setItem(`ff-${flagName}`, value);
}

export function clearLocalFlags() {
  Object.values(Flags).forEach((flagName) =>
    window.localStorage.removeItem(`ff-${flagName}`)
  );
}

/**
 * Merges feature flag permissions.
 */
export function getFlags(state) {
  return Object.values(Flags).reduce((acc, flag) => {
    acc[flag] = getFlagValue(state, flag);
    return acc;
  }, {});
}

// 👋 Let's keep this list sorted alphabetically! 👋
export const accountSettingsPermissionChecksEnabled = createLegacyGetFlagValue(
  Flags.ACCOUNT_SETTINGS_PERMISSION_CHECKS
);
export const adminControlsSettingsEnabled = createLegacyGetFlagValue(
  Flags.ADMIN_CONTROLS_SETTINGS
);
export const aemIntegrationEnabled = createFeatureFlagSelector(
  Flags.AEM_INTEGRATION
);
export const allowedDomainsSettingsEnabled = createFeatureFlagSelector(
  Flags.ALLOWED_DOMAINS
);
export const archivalStorageEnabled = createLegacyGetFlagValue(
  Flags.ARCHIVAL_STORAGE
);
export const v2AuthCookieCallbackEnabled = createFeatureFlagSelector(
  Flags.API_V2_AUTH_COOKIE_CALLBACK
);
export const betterUserManagementEnabled = createLegacyGetFlagValue(
  Flags.BETTER_USER_MANAGEMENT
);
export const c2cRealtimeLoggingEnabled = createFeatureFlagSelector(
  Flags.C2C_REALTIME_LOGGING
);
export const commentTimestampEditingEnabled = createLegacyGetFlagValue(
  Flags.COMMENT_TIMESTAMP_EDITING
);
export const developerTokensEnabled = createLegacyGetFlagValue(
  Flags.DEVELOPER_TOKENS
);

export const drmSettingsUIEnabled = createLegacyGetFlagValue(
  Flags.DRM_SETTINGS_UI
);

export const dualViewEnabled = createFeatureFlagSelector(Flags.DUAL_VIEW);
export const enterpriseDataRestrictionsEnabled = createFeatureFlagSelector(
  Flags.ENTERPRISE_DATA_RESTRICTIONS
);
export const folderSharingEnabled = createFeatureFlagSelector(
  Flags.FOLDER_SHARING
);

export const forcedVersioningEnabled = createFeatureFlagSelector(
  Flags.FORCED_VERSIONING
);

/*
  NOTE: `DISABLE_*` is a bit of an awkward name for a feature flag.
  Instead, we name the feature flag internally (e.g. `externalSharingEnabled`)
  and negate the value of the feature flag below.
*/
export const legacyGetFlagForDisabledAssetPublishing = createFeatureFlagSelector(
  Flags.DISABLE_ASSET_PUBLISHING
);
export const legacyGetFlagForDisabledPublicSharing = createFeatureFlagSelector(
  Flags.DISABLE_PUBLIC_SHARING
);
export const assetPublishingEnabled = (state) =>
  !legacyGetFlagForDisabledAssetPublishing(state);
export const externalSharingEnabled = (state) =>
  !legacyGetFlagForDisabledPublicSharing(state);
export const groupsManagementEnabled = createLegacyGetFlagValue(
  Flags.GROUPS_MANAGEMENT
);

export const newProjectInviteLinksEnabled = createFeatureFlagSelector(
  Flags.NEW_PROJECT_INVITE_LINKS
);
export const newSharingModalEnabled = createLegacyGetFlagValue(
  Flags.NEW_SHARING_MODAL
);
export const oauthAppsEnabled = createLegacyGetFlagValue(Flags.APPS);
export const pipelineDocumentServiceEnabled = createFeatureFlagSelector(
  Flags.PIPELINE_DOCUMENT_SERVICE
);
export const player360Enabled = createLegacyGetFlagValue(Flags.PLAYER_360);

export const privateProjectPermissions = createFeatureFlagSelector(
  Flags.PRIVATE_PROJECT_PERMISSIONS
);
export const playheadBroadcastEnabled = createLegacyGetFlagValue(
  Flags.BROADCAST_PLAYHEAD
);
export const pseudoMobileFullscreenEnabled = createFeatureFlagSelector(
  Flags.PSEUDO_MOBILE_FULLSCREEN
);
export const removePrintCommentsOptionEnabled = createFeatureFlagSelector(
  Flags.REMOVE_PRINT_COMMENTS_OPTION
);
export const restrictedCollaboratorRoleEnabled = createLegacyGetFlagValue(
  Flags.NEW_COLLABORATOR
);
export const restrictedDownloadOptionsEnabled = createLegacyGetFlagValue(
  Flags.RESTRICTED_DOWNLOAD_OPTIONS
);
export const reviewLinkCommentsToggleEnabled = createLegacyGetFlagValue(
  Flags.TOGGLE_REVIEW_LINK_COMMENTS
);
export const secureSharingEnabled = createLegacyGetFlagValue(
  Flags.SECURE_SHARING
);
export const stickyLinkSettingsEnabled = createLegacyGetFlagValue(
  Flags.STICKY_LINK_SETTINGS
);
export const teamWatermarkingColumnEnabled = createLegacyGetFlagValue(
  Flags.TEAM_WATERMARKING_COLUMN
);
export const trackingDebugEnabled = createFeatureFlagSelector(
  Flags.TRACKING_DEBUG
);
export const updatedArchivalMessagingEnabled = createFeatureFlagSelector(
  Flags.UPDATED_ARCHIVAL_MESSAGING
);
export const apiV3Enabled = createFeatureFlagSelector(Flags.V3_API);
export const watermarkDownloadsEnabled = createFeatureFlagSelector(
  Flags.WATERMARK_DOWNLOAD
);
export const watermarkIdEndUserOverrideEnabled = createFeatureFlagSelector(
  Flags.WATERMARK_ID_END_USER_OVERRIDE
);
export const webhooksEnabled = createLegacyGetFlagValue(Flags.WEBHOOKS);

export const v4IsBetaEnabled = createFeatureFlagSelector(Flags.V4_IS_BETA);

export const v4MigrationModal = createFeatureFlagSelector(
  Flags.V4_MIGRATION_MODAL
);

export const v8PricingOnlyAndMigrationFlowsUpdatesEnabled = createFeatureFlagSelector(
  Flags.V8_PRICING_ONLY_AND_MIGRATION_FLOWS_UPDATES
);

// 👋 Let's keep this list sorted alphabetically! 👋

export const testExports = {
  currentUserFeatureFlagsSelector,
  currentAccountFeatureFlagsSelector,
  createLegacyGetFlagValue,
  getFlagValue,
};
